import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav, Row, Col} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import satelite_view from "../../assests/images/satelite_view.jpg";
import street_view from "../../assests/images/street_view.jpg";

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import mapCardImg from "../../assests/images/gallery1.jpg";
import FsLightbox from 'fslightbox-react';

import Title_Block from  "../modules/title_block"; 

const YourHome = (props) => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('preval-presentation-your-home');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 0
    });
    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: ind
        });
    }

    var GalleryImages = [{'src_url': '', 'title': 'Photography', 'type': 'image'}, {'src_url': '', 'title': '360 Degree Tour', 'type': 'image'}, {'src_url': '', 'title': 'Photography', 'type': 'image'}];

    var lightImages2 = GalleryImages && GalleryImages.map(img => img.src_url);

    return <>
        <div className="section-wrapper">
          <Container>

            <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={config.preval_digital_domination} NextLink={''} NextPage={props.NextPage} PrevPage={props.PrevPage} />

            <p className="fig-content font_20">{article_data && HTMLReactParser(article_data.Content)}</p>
            {/* content-sec */}

            <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightImages2}
                slide={lightboxController.slide}
            />

            <Row className="your_home_data sell_properties photo_gallery">
              <div className="col-md-12">
                <Row>
                  {
                      GalleryImages.map((item, index) => {  
                      return(
                          <Col lg={4} md={4}>
                              <div className="map-card">                                  
                                  {
                                    item.src_url ? (
                                    <a href="javascript:;" onClick={(e) => openLighboxMobile(e, index + 1)}>
                                      <figure>
                                          <img src={item.src_url} alt="map-img" />
                                      </figure>
                                    </a>
                                    ) : (
                                      <span className="coming_soon">Image Coming Soon</span>
                                    )
                                  }
                                  
                              </div>
                              {/* card-body */}
                          </Col>
                      )
                      })
                  }
                  </Row>
              </div>

              {/* === map area sidebar === */}
              {/* <div className="col-md-4 p-0">
                  <div className="house_map">

                    <div className="map_area mb-3">
                      <figure>
                        <img src={satelite_view} alt="satelite_view" />
                      </figure>
                      <span>Satellite Image <a href="javascript:;">(Click to expand and explore)</a></span>
                    </div>

                    <div className="map_area">
                      <figure>
                        <img src={street_view} alt="street_view" />
                      </figure>
                      <span>Streetview <a href="javascript:;">(Click to expand and explore)</a></span>
                    </div>

                  </div>

              </div> */}
            </Row>
            
          </Container>
        </div>
  </>
}

export default YourHome